import React from 'react'
import styled from '@emotion/styled'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Metadata, Page } from '~/components/Layout'
import Hero from '~/sections/Care/Hero'
import PageSection from '~/sections/PageSection'
import { Content, TableOfContents } from '~/components/Care'

const CareAndCleaningPage = ({ data }) => {
  const { page } = data
  const byHero = section => section.type === 'care/Hero'
  const byIntro = section => section.type === 'care/Intro'
  const byContent = section =>
    section.type !== 'care/Hero' && section.type !== 'generic/PageCTA'
  const byCTA = section => section.type === 'generic/PageCTA'
  const hero = page.sections.find(byHero)
  const intros = page.sections.filter(byIntro)
  const sections = page.sections.filter(byContent)
  const cta = page.sections.find(byCTA)

  return (
    <Page>
      <Metadata
        title={page.metadata && page.metadata.title}
        description={page.metadata && page.metadata.description.description}
        image={page.metadata && page.metadata.image.file.url}
      />
      {hero && (
        <Hero
          cookwareId={intros[0] && intros[0].id}
          bakewareId={intros[1] && intros[1].id}
        />
      )}

      <ContentWrapper>
        <TableOfContents sections={sections} />
        <Content sections={sections} />
      </ContentWrapper>

      {cta && <PageSection key={cta.id} section={cta} />}
    </Page>
  )
}

export default CareAndCleaningPage

export const query = graphql`
  query CareAndCleaningQuery($locale: String) {
    page: contentfulPage(
      slug: { eq: "care-and-cleaning" }
      node_locale: { eq: $locale }
    ) {
      ...PageFragment
    }
  }
`

const ContentWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  max-width: 1440px;
  margin: 0 auto;
`

CareAndCleaningPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.shape({
      title: PropTypes.string,
      sections: PropTypes.arrayOf(PropTypes.shape({})),
      metadata: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.shape({
          description: PropTypes.string
        }),
        image: PropTypes.shape({
          file: PropTypes.shape({
            url: PropTypes.string
          })
        })
      })
    })
  })
}
