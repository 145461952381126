import React from 'react'
import PropTypes from 'prop-types'
import PageSection from '~/sections/PageSection'
import { Wrapper } from './styles'

const Content = ({ sections }) => (
  <Wrapper>
    {sections.map(section => (
      <PageSection key={section.id} section={section} />
    ))}
  </Wrapper>
)

export default Content

Content.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({}))
}
