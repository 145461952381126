import styled from '@emotion/styled'

export const Wrapper = styled.div`
  display: none;

  @media screen and (min-width: 992px) {
    display: block;
    min-width: 350px;
    position: sticky;
    padding: 104px 32px 32px 100px;
    top: 40px;
  }

  @media screen and (min-width: 1280px) {
    flex: 1;
  }
`

const Link = styled.a`
  display: block;
  width: fit-content;
  color: black;
`

export const MainLink = styled(Link)`
  font-size: 20px;
  line-height: 24px;
  margin-top: 40px;
`

export const SubLink = styled(Link)`
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
  margin-left: 20px;
`
