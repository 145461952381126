import React from 'react'
import PropTypes from 'prop-types'
import { Wrapper, MainLink, SubLink } from './styles'

const TableOfContents = ({ sections }) => {
  const links = sections.filter(s => s.type !== 'care/IntroImage')

  return (
    <Wrapper>
      {links.map(section =>
        section.type === 'care/Intro' ? (
          <MainLink key={section.id} href={`#${section.id}`}>
            {section.heading.includes('Cook') ? 'Cookware' : 'Bakeware'}
          </MainLink>
        ) : (
          <SubLink key={section.id} href={`#${section.id}`}>
            {section.type === 'care/FAQ' ? 'FAQs' : section.heading}
          </SubLink>
        )
      )}
    </Wrapper>
  )
}

export default TableOfContents

TableOfContents.propTypes = {
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      section: PropTypes.shape({
        id: PropTypes.string,
        heading: PropTypes.string,
        type: PropTypes.string
      })
    })
  )
}
