import styled from '@emotion/styled'

export const Wrapper = styled.div`
  flex: 1;
  max-width: 930px;
  // padding: 0 24px;

  > div {
    scroll-margin-top: 118px;
  }

  @media screen and (min-width: 992px) {
    padding: 0;
  }

  @media screen and (min-width: 1280px) {
    min-width: 930px;
    flex: 0;
  }
`
